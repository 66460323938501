import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInventory}      from "@fortawesome/pro-solid-svg-icons/faInventory";
import GenericModelButton from "app/main/genericComponents/buttons/modelButtons/GenericModelButton";

class EmplacementButton extends Component {

    render() {
        return (
            <GenericModelButton
                {...this.props}
                tooltip={this.props.emplacement.supply_emplacement_no}
                url={"/emplacements/" + this.props.emplacement.id}
                buttontext={ this.props.emplacement.supply_emplacement_code}
                startIcon={<FontAwesomeIcon icon={faInventory} className={"opacity-75 ml-4"}/>}
            />
        );
    }
}

export default (EmplacementButton);
