import axios                     from "axios";
import {apiCredentials, apiUrl}  from "app/services/axios/configApi";
import history                   from "@history";
import {setSession, setUserData} from "../../auth/store/actions";
import store                     from "../../store";

var customInterceptors = [];
/**
 * Intercepts the request before it is sent to the server
 * Verify if the user has the permissions to send a request to this url with this method
 */
axios.interceptors.request.use(function (config) {
    config.headers["Authorization"] = (localStorage.getItem("access_token")) ? "Bearer " + JSON.parse(localStorage.getItem("access_token")).user.auth_token : null;
    config.headers["Accept"] = "application/json";
    return config;
});

/**
 * Intercepts the request before it is sent to the server
 * Verify if the request will make an update to the customer cart
 */
axios.interceptors.response.use(function (response) {
    customInterceptors.forEach(interceptor => {
        interceptor.interceptor(response);
    })
    return response;
});

/**
 * Intercepts the axios request after a response is received by the request.
 */
axios.interceptors.response.use(function (response) {
    if (Array.isArray(response.data.data)) {
        response.data.data.forEach((object) => changeNullToEmpttyString(object));
    } else if (response.headers["content-type"] !== "text/html; charset=UTF-8"
        && response.headers["content-type"] !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && response.headers["content-type"] !== "text/csv;charset=UTF-8" && response.headers["content-type"] !== "text/csv; charset=UTF-8" && response.headers["content-type"] !== "application/pdf" && response.headers["content-type"] !== "text/plain;charset=UTF-8" && response.headers["content-type"] !== "text/plain; charset=UTF-8") {
        response.data.data = changeNullToEmpttyString(response.data.data);
    }

    return response;
}, async function (err) {
    if (err && err.response && err.response.status === 401 && err.response && err.config.url !== apiUrl + "/oauth/token") {
        const refreshToken = localStorage.getItem("access_token") ? JSON.parse(localStorage.getItem("access_token")).user.refresh_token : null;
        if (refreshToken) {
            const newResponse = await sendRefreshToken(err, apiCredentials, refreshToken);
            if (newResponse.status === 200) {
                return newResponse;
            }
        } else {
            setSession();
            history.push("/login");
        }
    } else if (err && err.response && err.response.status === 403) {
        history.push("/");
    }
    return Promise.reject(err);
});

function changeNullToEmpttyString(object) {
    for (let data in object) {
        if (typeof (object[data]) === "object") {
            changeNullToEmpttyString(object[data]);
        }
        if (object[data] === null) {
            object[data] = "";
        }
    }
    return object;
}

export async function download(response, name = "") {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    if (name === "") {
        link.setAttribute("download", `file.${response.headers["content-type"].split("/")[1].split(";")[0]}`);
    } else {
        link.setAttribute("download", name);
    }
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}

export async function downloadCSV(response, csvName) {

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(response.data);
    hiddenElement.target = '_blank';

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = csvName + '.csv';
    hiddenElement.click();
}

export async function downloadXLSX(response, XLSXName) {
    var blob = response.data;
    var downloadLink = window.document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob([blob], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}));
    downloadLink.download = XLSXName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

export async function downloadXLSXFromBlob(blob, XLSXName) {
    var downloadLink = window.document.createElement('a');
    console.log(blob);
    downloadLink.href = window.URL.createObjectURL(new Blob([blob], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}));
    downloadLink.download = XLSXName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

/**
 * Function to send the request to refresh the authorization token
 * @param {Object} error Error from previous request
 * @param {Object} config Config from previous request
 * @param {String} refreshToken Refresh token that will be sent to the API
 * @returns {Promise<AxiosResponse<any> | never>} Return axios request
 */
export async function sendRefreshToken(error, config, refreshToken) {
    const data = {
        grant_type   : "refresh_token",
        refresh_token: refreshToken,
        client_id    : apiCredentials.clientID,
        client_secret: apiCredentials.clientSecret,
        scope        : "",
    };
    return await axios.post(apiUrl + "/oauth/token", data).then(async json => {

        let userData = {
            auth_token   : json.data.access_token,
            expires_in   : json.data.expires_in,
            token_type   : json.data.token_type,
            refresh_token: json.data.refresh_token,
        };

        let access_token = {
            isLoggedIn: true,
            user      : userData,
        };

        setSession(access_token);
        await axios.get(apiUrl + "/auth/currentInfo").then(response => {
            if (response) {
                store.dispatch(setUserData(response.data.data));
            } else {
                return response.data.error;
            }
        });
        return await sendLastRequest(error);
    }).catch((err) => {
        setSession();
        history.push("/");
        return err;
    });
}

/**
 * Function to get the permissions.
 * It will be used after getting success from the refresh token.
 * @param {Object} errorResponse Error from previous request
 * @returns {Promise<AxiosResponse<any> | never>} Return axios request
 */
export async function sendLastRequest(errorResponse) {
    const urlRequest = errorResponse.response.config.url;
    const method = errorResponse.response.config.method;
    if (urlRequest && method) {
        switch (method) {
            case "get":
                return await axios.get(urlRequest, errorResponse.response.config).then(response => {
                    return response;
                });
            case "post":
                return await axios.post(urlRequest, errorResponse.response.config.data, errorResponse.response.config).then(response => {
                    return response;
                });
            case "delete":
                return await axios.delete(urlRequest, errorResponse.response.config).then(response => {
                    return response;
                });
            case "put":
                return await axios.put(urlRequest, errorResponse.response.config.data, errorResponse.response.config).then(response => {
                    return response;
                });
            case "patch":
                return await axios.patch(urlRequest, errorResponse.request.config.data, errorResponse.request.config).then(response => {
                    return response;
                });
            default:
                return errorResponse;
        }
    }
    return errorResponse;
}

export function addInterceptor(interceptor) {
    customInterceptors.push(interceptor);
}

export function removeInterceptor(interceptorKey) {
    customInterceptors.filter(interceptor => interceptor.key === interceptorKey);
}

export default axios;
